export const environment = {
  production: true,
  api: 'http://localhost:9000',
  firebase: {
    apiKey: 'AIzaSyC79esYSNJ5_83V16qQlOWQbTshkpseejk',
    authDomain: 'slumber-server.firebaseapp.com',
    projectId: 'slumber-server',
  },
  iOSApiKey: 'Ll54e474reD6yj0Jp9rgb72P7ShgJaMwJMhLlM20K5DzJP',
  AndroidApiKey: '1zUbjdhXWjjJYBDAwyKmAEeFNGktZ3ZBoFiVetAWAp99LA',
  bugsnagApiKey: '2985c1c12d879d2685822e8bd8626d61',
  fingerprintJSToken: 'gEkgE6tkDdYhS4E8CjyK',
  fingerprintJSEndpoint: 'https://fp.slumber.fm',
  disableBugsnag: false,
  appVersion: 'release-2.45.0'
};
