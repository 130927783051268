<ng-container [formGroup]="categoryFormGroup">
  @if (categoryFormGroup.get('category.id')) {
    <mat-form-field>
      <mat-label>Id</mat-label>
      <input matInput type="text" [formControl]="categoryFormGroup.get('category.id') | formControl" />
    </mat-form-field>
  }
  <ng-container formGroupName="category">
    <ng-container formGroupName="categoryTranslation">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input
          required
          matInput
          type="text"
          formControlName="title"
          (focusout)="onFocusOutEvent($event)"
          (input)="similarCategoryWarning = null"
        />
        @if (similarCategoryWarning) {
          <mat-hint>{{ similarCategoryWarning }}</mat-hint>
        }
      </mat-form-field>
    </ng-container>

    <mat-form-field>
      <mat-label>Key</mat-label>
      <input required matInput type="text" formControlName="key" />
    </mat-form-field>

    <ng-container formGroupName="categoryTranslation">
      <mat-form-field>
        <mat-label>Detail</mat-label>
        <textarea required matInput type="text" formControlName="detail" rows="4"> </textarea>
      </mat-form-field>
    </ng-container>

    @if (!isDefaultSlumber) {
      <mat-form-field>
        <mat-label>Translation Context</mat-label>
        <textarea required matInput type="text" formControlName="translationContext" rows="2"> </textarea>
        <mat-hint class="translationContextHint"
          >The translation context is additional info that helps the translators make the best translation.
          <a
            href="https://www.notion.so/slumbergroup/Localization-75685da35cb244d29b791668dace4882?pvs=4#f8bd1b6184074a389c714607261a8d42"
            class="translationContextHintLink"
            >How to write a context.</a
          ></mat-hint
        >
      </mat-form-field>
    }

    <label class="label">
      Category Type
      <mat-radio-group style="display: flex; flex-direction: column" formControlName="categoryTypeId">
        @for (categoryType of categoryTypes; track categoryType) {
          <div style="display: flex; align-items: center">
            <mat-radio-button style="margin: 5px" [value]="categoryType.id">
              {{ categoryType.title }}
            </mat-radio-button>
            <mat-icon style="margin-left: 5px" [matTooltip]="categoryType.detail">info</mat-icon>
          </div>
        }
      </mat-radio-group>
    </label>

    <app-publish-status
      [statusCodeControl]="statusCodeControl"
      [unpublishedAtControl]="unpublishedAtControl"
      [releasedAtControl]="releasedAtControl"
    >
    </app-publish-status>
  </ng-container>

  @if (allItems == null) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  @if (allItems) {
    <app-chip-list2
      [allItems]="allItems"
      (toggle)="toggleItem($event)"
      [selectedItems]="selectedItems"
      [disabled]="isTrackSelectionDisabled"
      [kind]="Kind.Track"
    >
    </app-chip-list2>
    @if (isDefaultSlumber) {
      <app-chip-list2
        [allItems]="allItems"
        (toggle)="toggleItem($event)"
        [selectedItems]="selectedItems"
        [kind]="Kind.Collection"
      >
      </app-chip-list2>
    }
    @if (isDefaultSlumber) {
      <app-chip-list2
        [allItems]="allItems"
        (toggle)="toggleItem($event)"
        [selectedItems]="selectedItems"
        [kind]="Kind.Person"
      >
      </app-chip-list2>
    }
    <app-reorder-items2
      [enableCheckbox]="isDefaultSlumber"
      (change)="updateFormGroupItems()"
      [items]="selectedItems"
    ></app-reorder-items2>
  }

  <div class="slumber-color-red">
    @if (categoryFormGroup.hasError('cleanFormRequired')) {
      <div>Please provide a value for all required fields</div>
    }
  </div>
</ng-container>
