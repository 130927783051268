import Bugsnag from '@bugsnag/js';

export enum StorageKeys {
  VISITOR_ID = 'VISITOR_ID',
  DEFAULT_APP = 'DEFAULT_APP',
}

export const LANGUAGE_ID = 1;

export const SwaggerDocumentationLibrary = 'https://app.swaggerhub.com/apis-docs/Slumber/slumber/3.1.1';

export enum Roles {
  Director = 1,
  Manager = 2,
  Viewer = 3,
  Dev = 4,
}

export enum SlumberCategories {
  Soundscapes = 12,
  Music = 13,
  Free = 20,
}

export enum CategoryTypes {
  Server = 1,
  ServerComputed = 2,
  ClientComputed = 3,
}

export enum ContentTypes {
  Voice = 1,
  Soundscape = 2,
  Music = 3,
}

export enum StatusCodes {
  IMMEDIATE = -1,
  DRAFT = 100,
  SCHEDULE = 200,
  BETA = 500,
  PUBLISHED = 800,
  GENERIC_ERROR_CODE = 1000,
  PROCESSING_AUDIO_ERROR_CODE = 1001,
}

export enum LibraryVersion {
  v_3_2 = 'v3.2',
  v_3_1 = 'v3.1',
  v_3_0 = 'v3',
}

export enum Hosts {
  API = 'api.slumber.fm',
  Prod = 'admin.slumber.fm',
  Staging = 'staging.slumber.fm',
  DevStaging = 'dev-staging.slumber.fm',
  Dev = 'localhost',
}

export enum MobileClient {
  IOS,
  Android,
}

export enum Stages {
  Prod = 'prod',
  Staging = 'staging',
  Dev = 'dev',
  Unknown = 'unknown',
}

export enum FileTypes {
  Image = 'image',
  Media = 'media',
}

export enum FileFormats {
  MP3 = 'mp3',
  CAF = 'caf',
  WAV = 'wav',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export enum TenantApp {
  Slumber = 'Slumber',
  DSS = 'Deep Sleep Sounds',
}

export enum TenantAppRouter {
  Slumber = 'slumber',
  DSS = 'deep-sleep-sounds',
}

export const ValidateTenantAppRouter = (app: string): TenantAppRouter | null => {
  if (app.toLowerCase() === TenantAppRouter.DSS.toLowerCase()) {
    return TenantAppRouter.DSS;
  } else if (app.toLowerCase() === TenantAppRouter.Slumber.toLowerCase()) {
    return TenantAppRouter.Slumber;
  }

  return null;
};

export const GetTenantAppRouterFromTenantApp = (app: string): TenantAppRouter | null => {
  if (app.toLowerCase() === TenantApp.DSS.toLowerCase()) {
    return TenantAppRouter.DSS;
  } else if (app.toLowerCase() === TenantApp.Slumber.toLowerCase()) {
    return TenantAppRouter.Slumber;
  }

  return null;
};

export function GetStageFromHost() {
  if (window.location.host === Hosts.Prod) {
    return Stages.Prod;
  } else if (window.location.host === Hosts.Staging) {
    return Stages.Staging;
  } else if (window.location.host === Hosts.DevStaging) {
    return Stages.Staging;
  } else if (window.location.host.split(':')[0] === Hosts.Dev) {
    return Stages.Dev;
  } else {
    Bugsnag.notify(`unexpected environment ${window.location.host}`);
    return Stages.Unknown;
  }
}
